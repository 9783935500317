import React, {useState, useEffect, useRef} from 'react';
import './about.scss';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {

  const aboutRef = useRef()
  const {t} = useTranslation()

  const [phone, setPhone] = useState(window.innerWidth < 1024)
  const [nineObs, setNineObs] = useState(false)

  const nineObserver = new IntersectionObserver(e => {
    e.forEach(i => { if(i.isIntersecting) setNineObs(true)})
  })

  useEffect(() => {
    nineObserver.observe(document.querySelector(`#abt-mid`))
    window.scrollTo({top: 0, behavior: "smooth"})
  },[])

  return(
    <div id="abt-container">
      <div id="abt-header-cover"></div>
      <div id="abt" ref={aboutRef}>
        <div id="abt-mid">
          {(!phone && nineObs) && <div id="abt-mid-right">
            <div className="abt-mid-right-img"/>  
          </div>}
          {nineObs && <div id="abt-mid-left">
            <div id="abt-mid-title">{t('about.heading')}</div>
            {phone && <div id="abt-mid-left-img">
              <div id="abt-img-relative">
              <div className="abt-mid-img"/>  
              </div>
            </div>}
            <div id="abt-mid-subtitle">
            {t('about.text1')}
            <br/><br/>
            {t('about.text2')}
            <br /><br />
            {t('about.text3')}
            </div>
          </div>}
        </div>
        {nineObs && 
          <div id="abt-bottom">
            <div id="abt-bottom-title">{t('about.sub.head1')}</div>
            <div id="sis-center">
              <div className="sis-cards">
                <div className="sis-cards-left">
                  <div className="sis-cards-left-logo"></div>
                </div>
                <div className="sis-cards-right">
                  <div className="sis-cards-right-title">{t('about.sub.one.text1')}</div>
                  <div className="sis-cards-right-subtitle">{t('about.sub.one.text2')}</div>
                  <div className="sis-cards-right-link"><a href="https://www.rafamedicals.com">rafamedicals.com</a></div>
                </div>
              </div>
              <div className="sis-cards">
                <div className="sis-cards-left">
                  <div className="sis-cards-left-logo"></div>
                </div>
                <div className="sis-cards-right">
                  <div className="sis-cards-right-title">{t('about.sub.two.text1')}</div>
                  <div className="sis-cards-right-subtitle">{t('about.sub.two.text2')}</div>
                  <div className="sis-cards-right-link"><a href="http://www.asianmedicalcentre.com/">asianmedicalcenter.com</a></div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      <div id="abt-bottom-box-title">{t('management.heading')}</div>
      <div id="abt-bottom-box">
        <div id="abt-bottom-one">
          <div className="abt-bottom-indi">
            <div className="abt-bottom-indi-bottom">
              <div className="abt-bottom-indi-bottom-title">Thahir Toufiq Ali Al Halabi</div>
              <div className="abt-bottom-indi-bottom-subtitle">Chairman</div>
            </div>
          </div>
          <div className="abt-bottom-indi">
            <div className="abt-bottom-indi-bottom">
              <div className="abt-bottom-indi-bottom-title">Sayed Thangal</div>
              <div className="abt-bottom-indi-bottom-subtitle">Executive Director</div>
            </div>
          </div>
        </div>
        <div id="abt-bottom-two">asian</div>
        <div id="abt-bottom-three">
          <div className="abt-bottom-indi">
            <div className="abt-bottom-indi-bottom">
              <div className="abt-bottom-indi-bottom-title">V.P Sharfulddeen</div>
              <div className="abt-bottom-indi-bottom-subtitle">Executive Director</div>
            </div>
          </div>
          <div className="abt-bottom-indi">
            <div className="abt-bottom-indi-bottom">
              <div className="abt-bottom-indi-bottom-title">Ibrahim Kutty</div>
              <div className="abt-bottom-indi-bottom-subtitle">Head of Finance</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
 
export default AboutUs;
