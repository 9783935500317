import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Preloader from './Screen/Preloader/preloader'
import Main from './Screen/Main/Main/main'
import Home from './Screen/Main/Home/home'
import Insurance from './Screen/Main/Insurance/insurance'
import Doctors from './Screen/Main/Doctors/doctors'
import Health from './Screen/Main/Health/health';
import Management from './Screen/Main/Management/management';
import AboutUs from './Screen/Main/About/about';
import Gallery from './Screen/Main/Gallery/gallery';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';



function App() {

  // const {t, i18n} = useTranslation()

  // useEffect(() => {
  //   if(i18n.language === 'ar') {
  //     console.log('ar')
  //     document.documentElement.style.setProperty('--url-fontBold', './Assets/Fonts/NotoSansArabic/static/NotoSansArabic-Bold.ttf')
  //   }else {
  //     console.log('en')
  //     document.documentElement.style.setProperty('--url-fontBold', './Assets/Fonts/Bahnschrift/BAHNSCHRIFT\ 10.TTF')
  //   }

  //   console.log(document.documentElement.style.getPropertyValue('--url-fontBold'))
  // }, [t])
  
  return (
    <Router>
      <Routes>
        {/* <Route path="preloader" element={<Preloader />}/> */}
        <Route path="/" element={<Main />}>
          <Route exact index element={<Home />}/>
          <Route path="/insurance" element={<Insurance />}/>
          <Route path="/doctors" element={<Doctors />}/>
          <Route path="/health-packages" element={<Health />}/>
          <Route path="/management" element={<Management />}/>
          <Route path="/about-us" element={<AboutUs />}/>
          <Route path="/gallery" element={<Gallery />}/>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
