import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import './home.css'
import './new.scss';
import styled from 'styled-components';
import Department from '../Departments/department';

import DOne from '../../../Images/slides/aid2.jpg';
import DTwo from '../../../Images/slides/pills.jpg';
// import DThree  from '../../../Images/CLI/sFive.jpg'; 
// import DThree  from '../../../Images/slides/med.jpg'; 
import DThree from '../../../Images/Home-one/nFour.jpeg';
import DFour from '../../../Images/slides/med.jpg';

import POne from '../../../Images/slides/aid2.jpg';
import PTwo from '../../../Images/CLI/sFive.jpg';
import PThree from '../../../Images/Home-one/nFour.jpeg';
import PFour from '../../../Images/slides/med.jpg';
// import POne  from '../../../Images/CLI/sSix.jpg'; 
// import PTwo  from '../../../Images/Home-one/nOne.jpg';
// import PThree  from '../../../Images/Home-one/gThree.jpg'; 
// import PFour  from '../../../Images/CLI/sFive.jpg'; 

import bannerdoc1 from "../../../Images/Dr/2.jpg";
import bannerdoc2 from "../../../Images/Dr/3.JPG";
import bannerdoc3 from "../../../Images/Dr/5.jpg";
import bannerdoc4 from "../../../Images/Dr/6.jpg";
import bannerdoc5 from "../../../Images/Dr/10.JPG";
import bannerdoc6 from "../../../Images/Dr/22.JPG";
import bannerdoc7 from "../../../Images/Dr/19.jpg";
import bannerdoc8 from "../../../Images/Dr/25.jpg";
import bannerdoc9 from "../../../Images/Dr/24.JPG"
import bannerdoc10 from "../../../Images/Dr/14.JPG"
import managmentlogo from "../../../Images/Header/logo1B.svg"


import mOne from '../../../Assets/vision-mission/mission.png';
import mTwo from '../../../Assets/vision-mission/vission.png';
import mThree from '../../../Assets/vision-mission/value.png';
import { useTranslation } from 'react-i18next';

const Home = () => {

  const {t, i18n} = useTranslation()
  const nav = useNavigate();
  const aboutRef = useRef();

  const [phone, setPhone] = useState(window.innerWidth < 1024 ? true : false);
  const [num, setNum] = useState(true);
  const [oneImg, setOneImg] = useState(DThree);
  const [twoImg, setTwoImg] = useState(DFour);
  const [homeSix, setHomeSix] = useState(false);
  const [homeSeven, setHomeSeven] = useState(false);

  const [sevenObs, setSevenObs] = useState(false);
  const [threeObs, setThreeObs] = useState(false);
  const [nineObs, setNineObs] = useState(false);

  const [dpt, setDpt] = useState(false);
  const [dptData, setDptData] = useState(0);

  const sixHome = new IntersectionObserver(e => {
    e.forEach(i => {
      if (i.isIntersecting) { setHomeSix(true); }
      else { setHomeSix(false); }
    });
  });

  const sevenHome = new IntersectionObserver(e => {
    e.forEach(i => {
      if (i.isIntersecting) { setHomeSeven(true); }
      else { setHomeSeven(false); }
    });
  });

  const sevenObserver = new IntersectionObserver(e => {
    e.forEach(i => { if (i.isIntersecting) setSevenObs(true); });
  });

  const threeObserver = new IntersectionObserver(e => {
    e.forEach(i => { if (i.isIntersecting) setThreeObs(true); });
  });

  const nineObserver = new IntersectionObserver(e => {
    e.forEach(i => { if (i.isIntersecting) setNineObs(true); });
  });


  useEffect(() => {
    sixHome.observe(document.querySelector(`#home-box-six`));
    sevenObserver.observe(document.querySelector(`#home-box-seven-in-box`));
    sevenHome.observe(document.querySelector(`#home-box-five`));
    threeObserver.observe(document.querySelector(`#home-box-three-bottom`));
    nineObserver.observe(document.querySelector(`#home-box-nine-mid`));
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (!phone) {
      if (num) {
        setTimeout(() => { setNum(false); }, 5000);
        setOneImg(oneImg === DThree ? DOne : DThree);
      }
      else {
        setTimeout(() => { setNum(true); }, 5000);
        setTwoImg(twoImg === DFour ? DTwo : DFour);
      }
    } else {
      if (num) {
        setTimeout(() => { setNum(false); }, 5000);
        setOneImg(oneImg === PThree ? POne : PThree);
      }
      else {
        setTimeout(() => { setNum(true); }, 5000);
        setTwoImg(twoImg === PFour ? PTwo : PFour);
      }
    }
  }, [num]);


  //banner text//

  // const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  // const banners = [
  //   {
  //     title: '25+ years of Medical Experience',
  //     text: 'We offer multi speciality healthcare services under one roof',
  //   },
  //   {
  //     title: 'Now accepting all major insurance cards',
  //   },
  //   {
  //     title: 'Wide variety of Lab tests ',
  //     text: 'MOH approved Pre-employment to Iqamah Baladiya tests',
  //   },
  //   {
  //     title: 'Now accepting all major insurance cards',
  //     text: 'We offer multi speciality healthcare services under one roof',
  //   },

  // ];

  // useEffect(() => {
  //   const intervalTimer = setInterval(() => {
  //     setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
  //   }, 4000);

  //   // Clear the interval timer when the component unmounts
  //   return () => clearInterval(intervalTimer);
  // }, [banners.length]);




  const banners = [
    { title: t('banners.one.title'), text: t('banners.one.text') },
    { title: t('banners.two.title') },
    { title: t('banners.three.title'), text: t('banners.three.text') },
    { title: t('banners.four.title'), text: t('banners.four.text') },
  ];

  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const [showBanner, setShowBanner] = useState(true); 

  useEffect(() => {
    const transitionInterval = 3000; 

    const intervalId = setInterval(() => {
      setShowBanner(false); 
      setTimeout(() => {
        setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
        setShowBanner(true); 
      }, 500); 
    }, transitionInterval);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentBannerIndex]);


  
  useEffect(() => {

    const scrollers = document.querySelectorAll(".scroller");

    // If a user hasn't opted in for recuded motion, then we add the animation
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        // add data-animated="true" to every `.scroller` on the page
        scroller.setAttribute("data-animated", true);

        // Make an array from the elements within `.scroller-inner`
        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);

        // For each item in the array, clone it
        // add aria-hidden to it
        // add it into the `.scroller-inner`
        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          duplicatedItem.setAttribute("class", "scroll-img");
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []);




  return (
    <div id="home-container">

      {homeSix && <div id="home-box-zero-six"></div>}

      {homeSeven && <div id="home-box-zero-seven"></div>}

      {/* slides */}
      <div id="home-box-one">
        <div
          className="home-one-img"
          // style={{ backgroundImage: `url(${oneImg})` }}
          id={
            num === true ? "home-one-one-show" : "home-one-one-hide"
          } />
        <div
          className="home-one-img home-one-img-height"
          // style={{ backgroundImage: `url(${twoImg})` }}
          id={
            num === true ? "home-one-two-hide" : "home-one-two-show"
          } />
        <div id="home-box-one-top">
          <div id="home-box-one-top-box">
          </div>
        </div>
      </div>

      <div className="image-slider">


      <div className={`banner_heading ${showBanner ? 'show' : ''}`}>
      <h3>{banners[currentBannerIndex].title}</h3>
      <p>{banners[currentBannerIndex].text}</p>
    </div>


        <div className="scroller" data-direction="left" data-speed="fast">
          <div className="scroller__inner">
            <div className='scroll-img'>
              <div className="image-container">
                <img src={bannerdoc1} alt="" />
                <div className="banner-overlay">
                  <p className="overlay-text">Dr. Abdul Qadir</p>
                  <h3 className="overlay-subtext">Internist</h3>
                </div>
              </div>
            </div>
            <div className='scroll-img'>
              <img src={bannerdoc2} alt="" />
              <div className="banner-overlay">
                <p className="overlay-text">Dr.Mohammed Raihan</p>
                <h3 className="overlay-subtext">Dermatologist</h3>
              </div>
            </div>
            <div className='scroll-img'>
              <img src={bannerdoc3} alt="" />
              <div className="banner-overlay">
                <p className="overlay-subtext">Dr. Abubakar Siddiqh</p>
                <h3 className="overlay-subtext">E.N.T Surgeon</h3>
              </div>
            </div>
            <div className='scroll-img'>
              <img src={bannerdoc4} alt="" />
              <div className="banner-overlay">
                <p className="overlay-subtext">Dr. Sayed Rafiuddin</p>
                <h3 className="overlay-subtext">Orthodontist</h3>

              </div>
            </div>
            <div className='scroll-img'>
              <img src={bannerdoc5} alt="" />
              <div className="banner-overlay">
                <p className="overlay-subtext">Dr. Abul Kashem</p>
                <h3 className="overlay-subtext"management_logo>Pediatrician</h3>

              </div>
            </div>

            <div className='scroll-img'>
              <img src={bannerdoc6} alt="" />
              <div className="banner-overlay">
                <p className="overlay-subtext">Dr. Muhammad Hamza</p>
                <h3 className="overlay-subtext"management_logo>Ophthalmologist</h3>

              </div>
            </div>


            <div className='scroll-img'>
              <img src={bannerdoc7} alt="" />
              <div className="banner-overlay">
                <p className="overlay-subtext">Dr. Numeera Faryad</p>
                <h3 className="overlay-subtext"management_logo>Gynaecologist</h3>

              </div>
            </div>

            <div className='scroll-img'>
              <img src={bannerdoc8} alt="" />
              <div className="banner-overlay">
                <p className="overlay-subtext">Dr. Danish Qayyum</p>
                <h3 className="overlay-subtext"management_logo>Cardiologist</h3>

              </div>
            </div>

            <div className='scroll-img'>
              <img src={bannerdoc9} alt="" />
              <div className="banner-overlay">
                <p className="overlay-subtext">Dr. Mohammed Riad</p>
                <h3 className="overlay-subtext"management_logo>Orthopedic Specialist</h3>

              </div>
            </div>


            {/* <div className='scroll-img'>
              <img src={bannerdoc9} alt="" />
              <div className="banner-overlay">
                <p className="overlay-subtext">Dr. Olayash Abdulrahman</p>
                <h3 className="overlay-subtext"management_logo>Laboratory Specialist</h3>

              </div>
            </div> */}

            {/* <div className='scroll-img'>
              <img src={bannerdoc10} alt="" />
              <div className="banner-overlay">
                <p className="overlay-subtext">Dr. Rania Abdel Rahman</p>
                <h3 className="overlay-subtext"management_logo>Radiologist</h3>

              </div>
            </div> */}
          </div>
        </div>





      </div>



      {/* <div id="home-box-two">
        <div className="home-box-two-box">
          <div className="home-box-two-box-title">Specialist Doctors's</div>
          <div className="home-box-two-box-subtitle">
            Choose from our wide variety of specialists of all kind. Consulting hours for specialists are from morning and evening shifts only
          </div>
          <div className="home-box-two-box-btn">
            <a href="tel:+966 0125380891">MESSAGE US</a>
          </div>
        </div>
        <div className="home-box-two-box">
          <div className="home-box-two-box-title">General Practioner</div>
          <div className="home-box-two-box-subtitle">
            Our General Practioners range from a diverse set on nationalities.  GP doctors are available round the clock 24/7
          </div>
          <div className="home-box-two-box-btn">
            <a href="tel:+966 0125380891">MAKE A CALL</a>
          </div>
        </div>
        <div className="home-box-two-box">
          <div className="home-box-two-box-title">Iqamah & Baladiya Department</div>
          <div className="home-box-two-box-subtitle">
            We have special packages available for our Corporate clients and we also accept the customers for screening on a 24/7 basis
          </div>
          <div className="home-box-two-box-btn">
            <a href="mailto:info@asianpolyclinic.com">EMAIL US YOUR ENQUIRY</a>
          </div>
        </div>
      </div>  */}

      {/* about-us */}
      <div id="home-box-nine" ref={aboutRef}>
        <div id="home-box-nine-mid">
          {(!phone && nineObs) && <div id="home-box-nine-mid-right">
            <div className="home-box-nine-mid-right-img" />
          </div>}
          {nineObs && <div id="home-box-nine-mid-left">
            <div id="home-box-nine-mid-title" className={i18n.language === 'en' ? 'home-box-common-title-en' : 'home-box-common-title-ar'}>{t('about.heading')}</div>
            {phone && <div id="home-box-nine-mid-left-img">
              <div id="home-box-nine-img-relative">
                <div className="home-box-nine-mid-img" />
              </div>
            </div>}
            <div id="home-box-nine-mid-subtitle" className={i18n.language === 'en' ? 'home-box-common-subtitle-en' : 'home-box-common-subtitle-ar'}>
              {t('about.text1')}
              <br /><br />
              {t('about.text2')}
              {/* <br /><br /> */}
              {/* We also have our branches in Kodai and Azizia. Click on the link to know more. */}
            </div>

            <div id="home-box-nine-mid-btn" className={i18n.language === 'ar' && 'home-button-size-ar'} onClick={() => { nav(`../about-us`); }}>{t('buttons.read_more')}</div>
          </div>}
        </div>
      </div>

      {/* management */}
      <div className='management_section'>
        <div id="home-box-ten">
          {/* <div id="home-box-ten-title">OUR MANAGEMENT</div> */}
          <div id="home-box-ten-top">
            <div className='management_logo'>
              <img src={managmentlogo}></img>
              <h4>asian</h4>
            </div>
            <div id="home-box-ten-top-one">
              <div id="home-box-ten-top-one-title">{t('management.heading')}</div>
            </div>
          </div>
          {!phone &&
            <div id="home-box-ten-top-two">
              <div id="home-box-ten-top-two-btn" className={i18n.language === 'ar' && 'home-button-size-ar'} onClick={() => { nav(`../about-us`); }}>{t('buttons.view_all')}</div>
            </div>
          }


          <div id="home-box-ten-bottom">
            <div id="ten-bottom-one">
              <div className="home-box-ten-indi">
                <div className="home-box-ten-indi-bottom">
                  <div className="home-box-ten-indi-bottom-title">Thahir Toufiq Ali Al Halabi</div>
                  <div className="home-box-ten-indi-bottom-subtitle">Chairman</div>
                </div>
              </div>
              <div className="home-box-ten-indi">
                <div className="home-box-ten-indi-bottom">
                  <div className="home-box-ten-indi-bottom-title">Sayed Thangal</div>
                  <div className="home-box-ten-indi-bottom-subtitle">Executive Director</div>
                </div>
              </div>
            </div>
            <div className='management_content'>
              <h4>{t('management.text1')}</h4>
              <p className={i18n.language === 'en' ? 'home-box-common-subtitle-en' : 'home-box-common-subtitle-ar'}>
                {t('management.text2')}
                <br></br><br></br>
                {t('management.text3')}
                <br></br><br></br>
              </p>
            </div>
            <div id="ten-bottom-three">
              <div className="home-box-ten-indi">
                <div className="home-box-ten-indi-bottom">
                  <div className="home-box-ten-indi-bottom-title">V.P Sharfulddeen</div>
                  <div className="home-box-ten-indi-bottom-subtitle">Executive Director</div>
                </div>
              </div>
              <div className="home-box-ten-indi">
                <div className="home-box-ten-indi-bottom">
                  <div className="home-box-ten-indi-bottom-title">Ibrahim Kutty</div>
                  <div className="home-box-ten-indi-bottom-subtitle">Head of Finance</div>
                </div>
              </div>
            </div>
          </div>

          {phone &&
            <div id="home-box-ten-phone-btn-box">
              <div id="home-box-ten-phone-btn" className={i18n.language === 'ar' && 'home-button-size-ar'} onClick={() => { nav(`../about-us`); }}>{t('buttons.view_all')}</div>
            </div>
          }
        </div>
      </div>

      {/* mission-vision-value */}
      <div id="home-box-five">
        <div id="home-box-five-container">
          <div className="home-five-indi-box">
            <img src={mOne} className="home-five-indi-icon" />
            <div className={i18n.language === 'en' ? 'home-five-indi-title home-five-indi-title-en' : 'home-five-indi-title home-five-indi-title-ar'}>{t('mvv.mission.text1')}</div>
            <div className={i18n.language === 'en' ? 'home-five-indi-subtitle home-five-indi-subtitle-en' : 'home-five-indi-subtitle home-five-indi-subtitle-ar'}>{t('mvv.mission.text2')}</div>
            {/* <div className="home-five-indi-btn">Read More</div> */}
          </div>
          <div className="home-five-indi-box">
            <img src={mTwo} className="home-five-indi-icon" />
            <div className={i18n.language === 'en' ? 'home-five-indi-title home-five-indi-title-en' : 'home-five-indi-title home-five-indi-title-ar'}>{t('mvv.vision.text1')}</div>
            <div className={i18n.language === 'en' ? 'home-five-indi-subtitle home-five-indi-subtitle-en' : 'home-five-indi-subtitle home-five-indi-subtitle-ar'}>{t('mvv.vision.text2')}</div>
            {/* <div className="home-five-indi-btn">Read More</div> */}
          </div>
          <div className="home-five-indi-box">
            <img src={mThree} className="home-five-indi-icon" />
            <div className={i18n.language === 'en' ? 'home-five-indi-title home-five-indi-title-en' : 'home-five-indi-title home-five-indi-title-ar'}>{t('mvv.value.text1')}</div>
            <div className={i18n.language === 'en' ? 'home-five-indi-subtitle home-five-indi-subtitle-en' : 'home-five-indi-subtitle home-five-indi-subtitle-ar'}>{t('mvv.vision.text2')}</div>
            {/* <div className="home-five-indi-btn">Read More</div> */}
          </div>
        </div>
      </div>

      {/* departments */}
      <div id="home-box-four">
        {dpt && <Department data={dptData} setDpt={setDpt} />}
        <div id="home-box-four-top">
          <div id="home-box-four-top-title" className={i18n.language === 'en' ? 'home-box-common-title-en' : 'home-box-common-title-ar'}>{t('department.heading')}</div>
          <div id="home-box-four-top-subtitle" className={i18n.language === 'en' ? 'home-box-common-subtitle-en' : 'home-box-common-subtitle-ar'}>
            {t('department.text1')}
          </div>
        </div>
        <div id="home-box-four-bottom">

          <div className="home-box-four-bottom-box">
            <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(0); setDpt(dpt ? false : true); }}>
              <div className="home-box-four-bottom-box-indi-icon">
                <img src={require('../../../Images/Departments/Black/1.png')} />
              </div>
              <div className="home-box-four-bottom-box-indi-title">INTERNAL</div>
              <div className={i18n.language === 'en' ? 'home-box-four-bottom-box-indi-subtitle hbfbbis-en' : 'home-box-four-bottom-box-indi-subtitle hbfbbis-ar'}>
                {t('department.INTERNAL.text1')}
              </div>
            </div>
            <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(1); setDpt(dpt ? false : true); }}>
              <div className="home-box-four-bottom-box-indi-icon">
                <img src={require('../../../Images/Departments/Black/2.png')} />
              </div>
              <div className="home-box-four-bottom-box-indi-title">PEDIATRICS</div>
              <div className={i18n.language === 'en' ? 'home-box-four-bottom-box-indi-subtitle hbfbbis-en' : 'home-box-four-bottom-box-indi-subtitle hbfbbis-ar'}>
                {t('department.PEDIATRICS.text1')}
              </div>
            </div>
            <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(2); setDpt(dpt ? false : true); }}>
              <div className="home-box-four-bottom-box-indi-icon">
                <img src={require('../../../Images/Departments/Black/3.png')} />
              </div>
              <div className="home-box-four-bottom-box-indi-title">DERMATOLOGY</div>
              <div className={i18n.language === 'en' ? 'home-box-four-bottom-box-indi-subtitle hbfbbis-en' : 'home-box-four-bottom-box-indi-subtitle hbfbbis-ar'}>
                {t('department.DERMATOLOGY.text1')}
              </div>
            </div>
            <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(3); setDpt(dpt ? false : true); }}>
              <div className="home-box-four-bottom-box-indi-icon">
                <img src={require('../../../Images/Departments/Black/4.png')} />
              </div>
              <div className="home-box-four-bottom-box-indi-title">GYNECOLOGY</div>
              <div className={i18n.language === 'en' ? 'home-box-four-bottom-box-indi-subtitle hbfbbis-en' : 'home-box-four-bottom-box-indi-subtitle hbfbbis-ar'}>
                {t('department.GYNECOLOGY.text1')}
              </div>
            </div>
            {
              phone &&
              <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(8); setDpt(dpt ? false : true); }}>
                <div className="home-box-four-bottom-box-indi-icon">
                  <img src={require('../../../Images/Departments/Black/9.png')} />
                </div>
                <div className="home-box-four-bottom-box-indi-title">ORTHOPEDICS</div>
              </div>
            }
            {
              phone &&
              <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(9); setDpt(dpt ? false : true); }}>
                <div className="home-box-four-bottom-box-indi-icon">
                  <img src={require('../../../Images/Departments/Black/10.png')} />
                </div>
                <div className="home-box-four-bottom-box-indi-title">PATHOLOGY</div>
              </div>
            }
          </div>

          <div className="home-box-four-bottom-box">
            <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(4); setDpt(dpt ? false : true); }}>
              <div className="home-box-four-bottom-box-indi-icon">
                <img src={require('../../../Images/Departments/Black/5.png')} />
              </div>
              <div className="home-box-four-bottom-box-indi-title">DENTISTRY</div>
              <div className={i18n.language === 'en' ? 'home-box-four-bottom-box-indi-subtitle hbfbbis-en' : 'home-box-four-bottom-box-indi-subtitle hbfbbis-ar'}>
                {t('department.DENTISTRY.text1')}
              </div>
            </div>
            <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(5); setDpt(dpt ? false : true); }}>
              <div className="home-box-four-bottom-box-indi-icon">
                <img src={require('../../../Images/Departments/Black/6.png')} />
              </div>
              <div className="home-box-four-bottom-box-indi-title">ENT</div>
              <div className={i18n.language === 'en' ? 'home-box-four-bottom-box-indi-subtitle hbfbbis-en' : 'home-box-four-bottom-box-indi-subtitle hbfbbis-ar'}>
                {t('department.ENT.text1')}
              </div>
            </div>
            <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(6); setDpt(dpt ? false : true); }}>
              <div className="home-box-four-bottom-box-indi-icon">
                <img src={require('../../../Images/Departments/Black/7.png')} />
              </div>
              <div className="home-box-four-bottom-box-indi-title">ORTHODONTICS</div>
              <div className={i18n.language === 'en' ? 'home-box-four-bottom-box-indi-subtitle hbfbbis-en' : 'home-box-four-bottom-box-indi-subtitle hbfbbis-ar'}>
                {t('department.ORTHODONTICS.text1')}
              </div>
            </div>
            <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(7); setDpt(dpt ? false : true); }}>
              <div className="home-box-four-bottom-box-indi-icon">
                <img src={require('../../../Images/Departments/Black/8.png')} />
              </div>
              <div className="home-box-four-bottom-box-indi-title">OPHTHALMOLOGY</div>
              <div className={i18n.language === 'en' ? 'home-box-four-bottom-box-indi-subtitle hbfbbis-en' : 'home-box-four-bottom-box-indi-subtitle hbfbbis-ar'}>
                {t('department.OPHTHALMOLOGY.text1')}
              </div>
            </div>
            {
              phone &&
              <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(10); setDpt(dpt ? false : true); }}>
                <div className="home-box-four-bottom-box-indi-icon">
                  <img src={require('../../../Images/Departments/Black/11.png')} />
                </div>
                <div className="home-box-four-bottom-box-indi-title">RADIOLOGY</div>
              </div>
            }
            {
              phone &&
              <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(11); setDpt(dpt ? false : true); }}>
                <div className="home-box-four-bottom-box-indi-icon">
                  <img src={require('../../../Images/Departments/Black/12.png')} />
                </div>
                <div className="home-box-four-bottom-box-indi-title">OTHER SERVICES</div>
              </div>
            }
          </div>

          {
            !phone &&
            <div className="home-box-four-bottom-box">
              <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(8); setDpt(dpt ? false : true); }}>
                <div className="home-box-four-bottom-box-indi-icon">
                  <img src={require('../../../Images/Departments/Black/9.png')} />
                </div>
                <div className="home-box-four-bottom-box-indi-title">ORTHOPEDICS</div>
                <div className={i18n.language === 'en' ? 'home-box-four-bottom-box-indi-subtitle hbfbbis-en' : 'home-box-four-bottom-box-indi-subtitle hbfbbis-ar'}>
                  {t('department.ORTHOPEDICS.text1')}
                </div>
              </div>
              <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(9); setDpt(dpt ? false : true); }}>
                <div className="home-box-four-bottom-box-indi-icon">
                  <img src={require('../../../Images/Departments/Black/10.png')} />
                </div>
                <div className="home-box-four-bottom-box-indi-title">PATHOLOGY</div>
                <div className={i18n.language === 'en' ? 'home-box-four-bottom-box-indi-subtitle hbfbbis-en' : 'home-box-four-bottom-box-indi-subtitle hbfbbis-ar'}>
                  {t('department.PATHOLOGY.text1')}
                </div>
              </div>
              <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(10); setDpt(dpt ? false : true); }}>
                <div className="home-box-four-bottom-box-indi-icon">
                  <img src={require('../../../Images/Departments/Black/11.png')} />
                </div>
                <div className="home-box-four-bottom-box-indi-title">RADIOLOGY</div>
                <div className={i18n.language === 'en' ? 'home-box-four-bottom-box-indi-subtitle hbfbbis-en' : 'home-box-four-bottom-box-indi-subtitle hbfbbis-ar'}>
                  {t('department.RADIOLOGY.text1')}
                </div>
              </div>
              <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(11); setDpt(dpt ? false : true); }}>
                <div className="home-box-four-bottom-box-indi-icon">
                  <img src={require('../../../Images/Departments/Black/12.png')} />
                </div>
                <div className="home-box-four-bottom-box-indi-title">OTHER SERVICES</div>
                <div className={i18n.language === 'en' ? 'home-box-four-bottom-box-indi-subtitle hbfbbis-en' : 'home-box-four-bottom-box-indi-subtitle hbfbbis-ar'}>
                  {t('department.OTHER SERVICES.text1')}
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      {/* doctors */}
      <div id="home-box-eight">
        <div id="home-box-eight-top">
          <div id="home-box-eight-top-one">
            <div id="home-box-eight-top-one-title" className={i18n.language === 'en' ? 'home-box-common-title-en' : 'home-box-common-title-ar'}>{t('doctors.head1')}</div>
            <div id="home-box-eight-top-one-subtitle" className={i18n.language === 'en' ? 'home-box-common-subtitle-en' : 'home-box-common-subtitle-ar'}>
              {t('doctors.text1')}
            </div>
          </div>
          {!phone &&
            <div id="home-box-eight-top-two">
              <div id="home-box-eight-top-two-btn" className={i18n.language === 'ar' && 'home-button-size-ar'} onClick={() => { nav(`../doctors`); }}>{t('buttons.view_all')}</div>
            </div>
          }
        </div>
        <div id="home-box-eight-bottom">
          <div className="home-box-eight-bottom-indi">
            <div className="home-box-eight-bottom-indi-top"></div>
            <div className="home-box-eight-bottom-indi-bottom">
              <div className="home-box-eight-bottom-indi-bottom-title">Dr. Abdul Qadir</div>
              <div className="home-box-eight-bottom-indi-bottom-subtitle">Internist</div>
            </div>
          </div>
          <div className="home-box-eight-bottom-indi">
            <div className="home-box-eight-bottom-indi-top"></div>
            <div className="home-box-eight-bottom-indi-bottom">
              <div className="home-box-eight-bottom-indi-bottom-title">Dr. Mohammed Raihan</div>
              <div className="home-box-eight-bottom-indi-bottom-subtitle">Dermatologist</div>
            </div>
          </div>
          <div className="home-box-eight-bottom-indi">
            <div className="home-box-eight-bottom-indi-top"></div>
            <div className="home-box-eight-bottom-indi-bottom">
              <div className="home-box-eight-bottom-indi-bottom-title">Dr. Abubakar Siddiqh</div>
              <div className="home-box-eight-bottom-indi-bottom-subtitle">E.N.T Surgeon</div>
            </div>
          </div>
          <div className="home-box-eight-bottom-indi">
            <div className="home-box-eight-bottom-indi-top"></div>
            <div className="home-box-eight-bottom-indi-bottom">
              <div className="home-box-eight-bottom-indi-bottom-title">Dr. Sayed Rafiuddin</div>
              <div className="home-box-eight-bottom-indi-bottom-subtitle">Orthodontist</div>
            </div>
          </div>
          <div className="home-box-eight-bottom-indi">
            <div className="home-box-eight-bottom-indi-top"></div>
            <div className="home-box-eight-bottom-indi-bottom">
              <div className="home-box-eight-bottom-indi-bottom-title">Dr. Abul Kashem</div>
              <div className="home-box-eight-bottom-indi-bottom-subtitle">Pediatrician</div>
            </div>
          </div>
        </div>
        {phone &&
          <div id="home-box-eight-phone-btn-box">
            <div id="home-box-eight-phone-btn" onClick={() => { nav(`../doctors`); }}>{t('buttons.view_all')}</div>
          </div>
        }
      </div>

      {/* insurence */}
      <div id="home-box-seven">
        <div id="home-box-seven-title"  className={i18n.language === 'en' ? 'home-box-common-title-en' : 'home-box-common-title-ar'}>{t('insurance.head1')}</div>
        <div id="home-box-seven-in-box">
          {
            sevenObs &&
            <div id="home-box-seven-in-anime">
              <div className="home-seven-in-indi"></div>
              <div className="home-seven-in-indi"></div>
              <div className="home-seven-in-indi"></div>
              <div className="home-seven-in-indi"></div>
              <div className="home-seven-in-indi"></div>
            </div>
          }
        </div>
        {/* <div id="home-box-seven-btn" onClick={() => {nav(`../insurance/${"kodai"}`,{state: {branchOne: true}})}}>{t('buttons.view_more')}</div> */}
        <div id="home-box-seven-btn"  className={i18n.language === 'ar' && 'home-button-size-ar'} onClick={() => { nav(`../insurance`, { state: { branchOne: true } }); }}>{t('buttons.view_more')}</div>
      </div>

      {/* facilities */}
      <div id="home-box-three">
        <div id="home-box-three-top">
          <div id="home-box-three-top-title" className={i18n.language === 'en' ? 'home-box-common-title-en' : 'home-box-common-title-ar'}>{t('facilities.head1')}</div>
          <div id="home-box-three-top-subtitle" className={i18n.language === 'en' ? 'home-box-common-subtitle-en' : 'home-box-common-subtitle-ar'}>
            {t('facilities.text1')}
          </div>
        </div>
        <div id="home-box-three-bottom">
          <div id="home-box-three-bottom-top">
            <div id="home-box-three-bottom-top-left">
              <div className="home-box-three-bottom-img" id="home-three-one"></div>
              <div id="home-box-three-bottom-top-left-top">
                {
                  threeObs &&
                  <div className={i18n.language === 'en' ? 'home-box-three-text-anime-one home-box-common-subtitle-en' : 'home-box-three-text-anime-one home-box-common-subtitle-ar'}>
                    {t('facilities.text2')}
                  </div>
                }
              </div>
            </div>
            {!phone && <div className="home-box-three-bottom-img" id="home-three-two"></div>}
            {!phone && <div className="home-box-three-bottom-img" id="home-three-three"></div>}
          </div>
          <div id="home-box-three-bottom-bottom">
            <div className="home-box-three-bottom-img" id="home-three-four"></div>
            {!phone && <div className="home-box-three-bottom-img" id="home-three-five"></div>}
            <div id="home-box-three-bottom-bottom-mid">
              {threeObs &&
                <div className="home-box-three-text-anime-two">
                  {/* The facilities provided within Asian Poly Clinic is made sure to be of the highest standards and quality available. We make no compromise in the ethics of service.  */}
                </div>
              }
            </div>
            {phone && <div className="home-box-three-bottom-img" id="home-three-five"></div>}
            {phone && <div className="home-box-three-bottom-img" id="home-three-six"></div>}
          </div>
          <div id="home-three-btn" className={i18n.language === 'ar' && 'home-button-size-ar'} onClick={() => { nav(`../gallery`); }}>{t('buttons.view_more')}</div>
        </div>
      </div>

      {/* apps */}
      <div id="home-box-six">
        <div id="home-six-left">
          <div id="home-six-title">{t('app.head1')}</div>
          {
            !phone &&
            <div className="home-six-logos">
              <div className="home-six-apps"></div>
              <div className="home-six-apps"></div>
            </div>
          }
          <div id="home-six-subtitle">{t('app.text1')}<br />{t('app.text2')}<br />{t('app.text3')}</div>
        </div>
        <div id="home-six-right">
          <div id="home-six-right-two"></div>
        </div>
        {
          phone &&
          <div className="home-six-logos">
            <div className="home-six-apps"></div>
            <div className="home-six-apps"></div>
          </div>
        }
      </div>

    </div>
  );
};

export default Home;