import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import styled from "styled-components";

const Preloader = () => {

  const nav = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      nav("/", {replace:true});
    }, 2000);
  }, []);

  return (
    <Cont>
      <Lottie
        loop
        autoPlay
        style={{ height: 100, width: 100 }}
        animationData={require("../../Assets/Animations/preloader.json")}
      />
    </Cont>
  );
};

export default Preloader;

const Cont = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;
