import React,{useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import './footer.scss'
import styled from 'styled-components'

import locationIcon from '../../../Images/Footer/location.svg'
import callIcon from '../../../Images/Footer/call.svg'
import mailIcon from '../../../Images/Footer/mail.svg'
import { useTranslation } from 'react-i18next'

const Footer = () => {

  const nav = useNavigate()
  const [phone, setPhone] = useState(window.innerWidth < 1024 ? true : false)
  const {t} = useTranslation()

  const observer = new IntersectionObserver(e => {
    e.forEach(i => {
      if(i.isIntersecting) { 
        let a = document.querySelectorAll(`.footer-top-box`)
        a.forEach(i => {
          if(phone) {
            i.classList.add('footer-top-mount-p')
          }else {
            i.classList.add('footer-top-mount')
          }
        })
       }
    })
  })


  useEffect(() => {
    observer.observe(document.querySelector(`#footer-container`))
  },[])

  return(
    <div id="footer-container">
      <div id="footer-top-container">
        <div id="footer-top-container-box">
          <a className="footer-top-box" href="https://maps.app.goo.gl/LxBYc6iv8gR8nRUU6" target="_blank">
            <div className="footer-top-box-left">
              <img src={locationIcon} alt="" className="footer-top-box-left-icon" />
            </div>
            <div className="footer-top-box-right">
              <div className="footer-top-box-right-title">{t('footer_one.one.text1')}</div>
              <div className="footer-top-box-right-subtitle">{t('footer_one.one.text2')}</div>
            </div>
          </a>
          <a className="footer-top-box" href="tel:+966 0125380891">
            <div className="footer-top-box-left">
              <img src={callIcon} alt="" className="footer-top-box-left-icon" />
            </div>
            <div className="footer-top-box-right">
              <div className="footer-top-box-right-title">{t('footer_one.two.text1')}</div>
              <div className="footer-top-box-right-subtitle">{t('footer_one.two.text2')}</div>
            </div>
          </a>
          <a className="footer-top-box" href="mailto:info@asianpolyclinic.com">
            <div className="footer-top-box-left">
              <img src={mailIcon} alt="" className="footer-top-box-left-icon" />
            </div>
            <div className="footer-top-box-right">
              <div className="footer-top-box-right-title">{t('footer_one.three.text1')}</div>
              <div className="footer-top-box-right-subtitle">{t('footer_one.three.text2')}</div>
            </div>
          </a>
        </div>
      </div>

      <div id="footer-mid-container" className="mid-index">
        <div className="footer-mid-box" id="footer-mid-one">
          <div className="footer-mid-title">{t('footer_two.contacts.head1')}</div>
          <div className="footer-mid-bottom">
            <div className="footer-mid-one-box">
            {
              localStorage.getItem('apc-branch') === 'Azizia' ? 
              <a className="footer-mid-one-right" href="https://maps.app.goo.gl/LxBYc6iv8gR8nRUU6" target="_blank">
                {t('footer_two.contacts.text1')}
              </a> :
              <a className="footer-mid-one-right" href="https://maps.app.goo.gl/LxBYc6iv8gR8nRUU6" target="_blank">
                {t('footer_two.contacts.text2')}
              </a>
            }
            </div>
            <div className="footer-mid-one-box">
              <a className="footer-mid-one-right" href="tel:+966 0125380891">{t('header.number1')}</a>
            </div>
            <div className="footer-mid-one-box">
              <a className="footer-mid-one-right" href="https://wa.me/9660125361280">{t('header.number2')}</a>
            </div>
            <div className="footer-mid-one-box">
              <div className="footer-mid-one-right" href="mailto:info@asianpolyclinic.com">info@asianpolyclinic.com</div>
            </div>
          </div>
        </div>
        <div className="footer-mid-box">
          <div className="footer-mid-title">{t('footer_two.navigator.head1')}</div>
          <div className="footer-mid-bottom-two">
            <div className="footer-mid-bottom-box">
              <div className="footer-mid-bottom-elements" onClick={() => {nav('/')}}>{t('footer_two.navigator.home')}</div>
              <div className="footer-mid-bottom-elements" onClick={() => {
                document.querySelector(`#home-box-nine`).scrollIntoView({behavior: "smooth", block: "center"})}}>{t('footer_two.navigator.about')}</div>
              {/* <div className="footer-mid-bottom-elements">Our Specialities</div> */}
              <div className="footer-mid-bottom-elements" onClick={() => {nav(`../doctors`,{state: {branchOne: true}})}}>{t('footer_two.navigator.our_doctors')}</div>
              <div className="footer-mid-bottom-elements" onClick={() => {
                document.querySelector(`#home-box-five`).scrollIntoView({behavior: "smooth", block: "center"})}}>{t('footer_two.navigator.mission')}</div>
            </div>
            <div className="footer-mid-bottom-box">
              <div className="footer-mid-bottom-elements" onClick={() => {
                document.querySelector(`#home-box-four`).scrollIntoView({behavior: "smooth", block: "start"})}}>{t('footer_two.navigator.departments')}</div>
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../health-packages')}}>{t('footer_two.navigator.health_packages')}</div>
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../about-us')}}>{t('footer_two.navigator.management')}</div>
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../insurance')}}>{t('footer_two.navigator.insurance')}</div>
            </div>
          </div>
        </div>
      </div>
      
      <div id="footer-bottom-container">
        <span>© {new Date().getUTCFullYear()} {t('footer_three.text1')}</span>
        <span> {t('footer_three.text2')}<a href="https://chzapps.com"> {t('footer_three.text3')}</a></span>
      </div>
    </div>
  )
}

export default Footer;