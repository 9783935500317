import i18next, { loadResources } from 'i18next'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // lng: 'en',
    fallbackLng: 'ar',
    resources: {
      en: {
        translation: {
          header: {
            number1: "+966 012 538 0891",
            number2: "012 5361280"
          },
          menus: {
            home: 'HOME',
            about: 'ABOUT',
            our_specialties: 'OUR SPECIALTIES',
            our_doctors: 'OUR DOCTORS',
            health_packages: 'HEALTH PACKAGES',
            insurance: 'INSURANCE',
            our_gallery: 'OUR GALLERY',
            contact_us: 'CONTACT US',
            switch_language: 'LANGUAGE',
            switch_language_en: 'ENGLISH',
            switch_language_ar: 'ARABIC'
          },
          banners: {
            one: {
              title: '25+ years of Medical Experience', text: 'We offer multi speciality healthcare services under one roof'
            },two: {
              title: 'Now accepting all major insurance cards'
            },three: {
              title: 'Wide variety of Lab tests', text: 'MOH approved Pre-employment to Iqamah Baladiya tests'
            },four: {
              title: 'Now accepting all major insurance cards', text: 'We offer multi speciality healthcare services under one roof'
            }
          },
          about: {
            heading: 'ABOUT US',
            text1: "Asian Polyclinic is an organization that drives the standards of primary health care to the new heights. The journey began in the year 1995 by launching our first Medical Clinic in Makkah, Misfalah. A relentless striving for service excellence and an all-important focus on quality perfection often drives us with new vigor and vitality. Our Clinics are the most coveted destinations for the people since our slogan is ‘Finest healthcare in best affordability’ and our pledge is 'Access to wellness to all'. We stress on improving the health of the community by setting up the standard of excellence in medical care, research, and clinical care. Aiming a patient-centric care, the hospital is utilizing enhanced lifesaving technologies for enhancing cure.",
            text2: "Through our 15+ specialties, we provide in-depth expertise in the spectrum of advance medical and surgical interventions. We assure our services in the Departments of Internal Medicine, Gynaecology, Paediatrics, General Medicine, ENT, Dermatology, Ophthalmology, Dental Surgery and much more. We have some of the best specialty doctors from arround the world, they bring years of experience and offer evidence based treatment to ensure the best care for you.",
            text3: "We also have our branches in Kodai and Azizia. Click on the link to know more.",
            sub: {
              head1: "Our Sister Concerns",
              one: {
                text1: "Rafa Medical Center",
                text2: "Prince Majid St, Alkurnaish, Al Khobar, Saudi Arabia",
                text3: "rafamedicals.com",
              },
              two: {
                text1: "Asian Medical Center",
                text2: "Majlis Al Taawon St, Al Wakrah, Qatar",
                text3: "asianmedicalcenter.com",
              }
            }
          },
          management: {
            heading: "OUR MANAGEMENT",
            text1: "Your Trusted Healthcare Provider",
            text2: "We are dedicated to serving the healthcare needs of our community with excellence, compassion, and cutting-edge medical expertise. With a legacy of experienced in providing quality healthcare services, we have earned the trust of patients and their families.",
            text3: "Our mission is to enhance the well-being of our patients by delivering personalized, patient-centered care. Our experienced and caring medical professionals work tirelessly to ensure your comfort and recovery. We take pride in our state-of-the-art facilities, advanced medical technology, and a commitment to maintaining the highest standards of quality and safety. Your health and wellness are our top priorities, and we are here to support you on your healthcare journey.",
          },
          mvv: {
            mission: {
              text1: "OUR MISSION",
              text2: "To enrich our distinguished services and optimize the healthcare environment for creating a delightful experience to one and all."
            },
            vision: {
              text1: "OUR VISION",
              text2: "To provide affordable and reliable healthcare through our distinctive services and achieve a global presence in the hospitatlity sector by being within our system."
            },
            value: {
              text1: "OUR VALUE",
              text2: "Delivering our professional best, providing the highest quality of services, being maximal in reliability and be the benchmark in the industry."
            },
          },
          department: {
            heading: "OUR DEPARTMENTS",
            text1: "We offer multi-speciality healthcare services under one roof",
            text2: "Available staff",
            INTERNAL: {
              head1: "INTERNAL",
              text1: "Providing treatment for adult age problems that are then treated by doctors specialized in the field.",
              text2: "The Speciality of Internal Medicine at Asian Poly Clinic deals with the prevention, diagnosis, and treatment of adult diseases. We mainly focus on the art of diagnosis and treatment with ample medications. We are managing the ailments as per internationally accepted protocols that lead to improved health outcomes. Our Internal Medicine Department provides full complement of preventive medical care, including routine screening and immunization, to help our patients to maintain and improve their health and also for identifying those patients who are at a high risk for specific conditions such as diabetes, cholesterol, heart diseases.",
            },
            PEDIATRICS: {
              head1: "PEDIATRICS",
              text1: "Provides best medical care for you child, preventing diseases, nutritioning back to health upto a legal age.",
              text2: "The Pediatric Department at Asian Poly Clinic deals with the care of children and adolescents. The health needs of children are extremely unique and different. We at Asian Poly Clinic aim to make a world of difference in the healthcare and wellbeing of both the children and adolescents. We provide facility for consultation of all pediatric patients who are examined, investigated and treated on an out patient basis. The department consist of highly specialized and qualified doctor who has good experience."
            },
            DERMATOLOGY: {
              head1: "DERMATOLOGY",
              text1: "APC’s Dermatology Department provides expert cosmetic care to patients.",
              text2: "Dermatology is the branch of medicine dealing with the skin. It is a speciality with both medical and surgical aspects. Our dermatology department has specialists who specilises in dealing, managing diseases related to skin, hair, nails, and some cosmetic problems."
            },
            GYNECOLOGY: {
              head1: "GYNECOLOGY",
              text1: "APC's gynecology department provides the best package health care for your loved ones.",
              text2: "With a team of best Gynecologists in Saudi Arabia, we provide comprehensive prenatal and maternity care for expecting mothers. Besides, the Gynecology Department in our clinics and hospitals in Saudi Arabia render various other services for the comprehensive and patient-focused management of gynecologic conditions in a family-focused healthcare milieu."
            },
            DENTISTRY: {
              head1: "DENTISTRY",
              text1: "The smiles we recive, tells the story of the happy customers we have, of our decade long service.",
              text2: "The APC personnel make a special effort to explain everything to you regarding your condition, medicines and treatment. To have strong, sparkling teeth means to achieve a fine dental health. It is absolutely important to take the right steps to care your teeth and deter dental illness. If there is anything that is unclear to you, please do not hesitate to ask questions. Each and every staff member provides quality dental services in a pleasant, efficient, and friendly atmosphere."
            },
            ENT: {
              head1: "ENT",
              text1: "Our board certified audiologists, can perform hearing tests to help find a cause.",
              text2: "We at Asian Poly Clinic, provides an integrated ear, nose and throat and audiology service. The audiology department provides a comprehensive diagnostic service of hearing and balance assessments for all age groups."
            },
            ORTHODONTICS: {
              head1: "ORTHODONTICS",
              text1: "The branch of dentistry concerned with growth and development of orofacial structures.",
              text2: "We believes that delivery of quality dental treatment begins at creating a patient-friendly environment, adhering to stringent aseptic methods. The clinic is updated appropriately in accordance with the recent advancements in Dentistry, thus making it accessible for all their patients. Despite being a very famous and sought after Orthodontic speciality, the clinic boasts of an equally excellent General practice ranging from Root Canal treatments, Crowns,Cosmetic dentistry with the most biocompatible teeth bleaching techniques"
            },
            OPHTHALMOLOGY: {
              head1: "OPHTHALMOLOGY",
              text1: "Ophthalmologists are trained to perform eye tests, diagnose, treat and prescribe medications.",
              text2: "At APC, we take the time to listen, ask questions and fully understand your concerns. By combining our higher standard of patient care with the latest diagnostic technologies, we identify current vision issues and help uncover warning signs of future vision challenges. Then we clearly explain our findings and discuss all appropriate medical and surgical options with you."
            },
            ORTHOPEDICS: {
              head1: "ORTHOPEDICS",
              text1: "Helping in encourageing development and facilitate recovery, enabling people to stay in work.",
              text2: "Substantial numbers of trauma and elective orthopedic operations are performed in Asian Poly Clinics every year. Our facilities provide exceptional care for patients with bone, joint, and associated muscular / skeletal injuries, from diagnosis through to surgery and other treatment modalities."
            },
            PATHOLOGY: {
              head1: "PATHOLOGY",
              text1: "The caring the injured, diagnosis of disease through the examination of surgically removed organs.",
              text2: "Clinical pathologists are in charge of the special divisions in the laboratory where they manage different work. The study of clinical pathology makes a significant contribution in nursing of the injured. They undergo training sessions where they receive training for all the latest tools and types of equipment that help diagnose a sample. With the help of a skilled pathologist, whom we provide with the best facilities, will help you know the exact cause of the diseases, and accordingly, you can proceed with the medication process. The whole procedure depends on the clinical results."
            },
            RADIOLOGY: {
              head1: "RADIOLOGY",
              text1: "With our latest tech in the industry, we provide the best and efficient results for our services.",
              text2: "We offer tremendous value by providing quality work at an affordable price. We offer superior pricing, unmatched in the industry. Our rates are unmatched due to streamlined and highly efficient operations. We reduce or eliminate expenses at every level. As a result, our customers save significantly with every read."
            },
            "OTHER SERVICES": {
              head1: "OTHER SERVICES",
              text1: "We provide other laboratory services like PCR test, Iqama tests, Baladiya tests and more.",
              text2: "At Asian Poly Clinic, we treats a wide range of tests and laboratory services related to legal and medcal issues like PCR test, Iqama test, Baladiya test, Medical checkups and many more. We belive in providing efficient and seamless services. Our 24/7 enquiry line is always open for your sevice to clarify anything regards the tests or our available services."
            },
            "GENERAL MEDICINE": {
              head1: "GENERAL MEDICINE",
              text1: "Our clinics provide comprehensive medical & general medicine services for each member of the family. At APC, our GP & Family Medicine practitioners aim to be your primary point of contact for routine medical services. General & Family Medicine practitioners diagnose & manage all primary level illnesses & refer you to specialist practitioners if needed. Our doctors are also specialized in providing well-managed preventive health care, thus providing you with a complete & holistic healthcare solution.",
            }
          },
          doctors: {
            head1: "OUR DOCTORS",
            text1: "We have some of the best specialty doctors from arround the world, they bring years of experience and offer evidence based treatment to ensure the best care for you."
          },
          health_packages: {
            head1: "HEALTH PACKAGES"
          },
          insurance: {
            head1: "INSURANCE AFFILIATES",
            text1: "Contact us",
            text2: "to know more about our insurance policy"
          },
          gallery: {
            head1: "OUR GALLERY",
            head2: "Empowering Our Team",
          },
          facilities: {
            head1: "OUR FACILITIES",
            text1: "We have the best facilities and technology to occupy in the sector in our hands to your hospitatlity.",
            text2: "Asian Poly Clinic always keeps up with the latest technologies to satisfy your needs. We make sure that our patients can experience the warm hospitatlity within an affordable price.",
          },
          app: {
            head1: "Introducing Our Asian App",
            text1: "Our Social interactive page, ",
            text2: "Get in touch with our Doctor's anytime, from your choice of ",
            text3: "social media channel",
          },
          footer_one: {
            one: {
              text1: "Find a clinic near you",
              text2: "Find Us On Map"
            },
            two: {
              text1: "Call us for enquiry",
              text2: "012 538 0891"
            },
            three: {
              text1: "Feel free to message us!",
              text2: "Send an Email"
            },
          },
          footer_two: {
            contacts: {
              head1: "CONTACT US",
              text1: "Asian Polyclinic, Makkah, Azizia, KSA",
              text2: "Asian Polyclinic, Makkah, Zahrat Kodai, KSA",
            },
            navigator: {
              head1: "QUICK LINKS",
              home: 'Home',
              about: 'About',
              our_doctors: 'Our Doctors',
              mission: 'Mission and Vision',
              departments: 'Departments',
              health_packages: 'Health Packages',
              management: 'Management',
              insurance: 'Insurance',
            }
          },
          footer_three: {
            text1: "Asian Poly Clinic, Inc. All rights reserved",
            text2: "Designed and Developed by ",
            text3: "CHZAPPS INDIA"
          },
          buttons: {
            view_more: "VIEW MORE",
            view_all: "VIEW ALL",
            read_more: "READ ALL",
          }
        }
      },
      ar: {
        translation: {
          header: {
            number1: "+٩٦٦٠١٢ ٥٣٨ ٠٨٩١",
            number2: "٠١٢ ٥٣٦ ١٢٨٠"
          },
          menus: {
            home: 'هم',
            about: 'أبوت',
            our_specialties: 'تخصصاتنا',
            our_doctors: 'أطباؤنا',
            health_packages: 'الحزم الصحية',
            insurance: 'عنصرانك',
            our_gallery: 'معرضنا',
            contact_us: 'اتصل بنا',
            switch_language: 'لغة',
            switch_language_en: 'إنجليش',
            switch_language_ar: 'أرابيك'
          },
          banners: {
            one: {
              title: 'أكثر من ٢٥ عامًا من الخبرة الطبية', text: 'نحن نقدم خدمات رعاية صحية متعددة التخصصات تحت سقف واحد'
            },two: {
              title: 'نقبل الآن جميع بطاقات العنصرانك الرئيسية'
            },three: {
              title: 'مجموعة واسعة من الاختبارات المعملية', text: 'وافقت وزارة الصحة على اختبارات ما قبل التوظيف في الإقامة البلدية'
            },four: {
              title: 'نقبل الآن جميع بطاقات العنصرانك الرئيسية', text: 'نحن نقدم خدمات رعاية صحية متعددة التخصصات تحت سقف واحد'
            }
          },
          about: {
            heading: 'معلومات عنا',
            text1: 'أسيا بلي كلينيك هي منظمة تدفع معايير الرعاية الصحية الأولية إلى آفاق جديدة. بدأت الرحلة في عام ١٩٩٥ بإطلاق أول عيادتنا الطبية في مكة المكرمة، المسفلة. إن السعي الدؤوب لتحقيق التميز في الخدمة والتركيز البالغ الأهمية على الجودة المثالية غالبًا ما يدفعنا بقوة وحيوية جديدة. عياداتنا هي أكثر الوجهات المرغوبة للناس لأن شعارنا هو "أفضل رعاية صحية بأفضل الأسعار المعقولة" وتعهدنا هو "الوصول إلى العافية للجميع". نحن نؤكد على تحسين صحة المجتمع من خلال وضع معايير التميز في الرعاية الطبية والأبحاث والرعاية السريرية. بهدف رعاية تتمحور حول المريض، يستخدم المستشفى تقنيات منقذة للحياة لتعزيز العلاج.',
            text2: "من خلال أكثر من 15 تخصصًا، نقدم خبرة متعمقة في نطاق التدخلات الطبية والجراحية المتقدمة. نحن نؤكد خدماتنا في أقسام الطب الباطني، أمراض النساء، طب الأطفال، الطب العام، الأنف والأذن والحنجرة، الأمراض الجلدية، طب العيون، جراحة الأسنان وأكثر من ذلك بكثير. لدينا بعض من أفضل الأطباء المتخصصين من جميع أنحاء العالم، فهم يتمتعون بسنوات من الخبرة ويقدمون العلاج القائم على الأدلة لضمان أفضل رعاية لك.",
            text3: "لدينا أيضًا فروعنا في كوداي والعزيزية. انقر على الرابط لمعرفة المزيد.",
            sub: {
              head1: "مخاوفنا الشقيقة",
              one: {
                text1: "رافا ميديكال سنتر",
                text2: "شارع الأمير ماجد، الكورنيش، الخبر، المملكة العربية السعودية",
                text3: "rafamedicals.com",
              },
              two: {
                text1: "أسيا ميديكال سنتر",
                text2: "شارع مجلس التعاون، الوكرة، قطر",
                text3: "asianmedicalcenter.com",
              }
            }
          },
          management: {
            heading: "إدارتنا",
            text1: "مقدم الرعاية الصحية الموثوق به",
            text2: "نحن ملتزمون بخدمة احتياجات الرعاية الصحية لمجتمعنا بالتميز والرحمة والخبرة الطبية المتطورة. بفضل تراثنا من الخبرة في تقديم خدمات رعاية صحية عالية الجودة، اكتسبنا ثقة المرضى وعائلاتهم",
            text3: "مهمتنا هي تعزيز رفاهية مرضانا من خلال تقديم رعاية شخصية تركز على المريض. يعمل المتخصصون الطبيون ذوو الخبرة والعناية لدينا بلا كلل لضمان راحتك وتعافيك. نحن نفخر بمرافقنا الحديثة والتكنولوجيا الطبية المتقدمة والالتزام بالحفاظ على أعلى معايير الجودة والسلامة. صحتك وعافيتك هي أهم أولوياتنا، ونحن هنا لدعمك في رحلة الرعاية الصحية الخاصة بك.",
          },
          mvv: {
            mission: {
              text1: "مهمتنا",
              text2: "إثراء خدماتنا المتميزة وتحسين بيئة الرعاية الصحية لخلق تجربة ممتعة للجميع."
            },
            vision: {
              text1: "رؤيتنا",
              text2: "توفير رعاية صحية موثوقة وبأسعار معقولة من خلال خدماتنا المميزة وتحقيق حضور عالمي في قطاع الضيافة من خلال التواجد ضمن نظامنا."
            },
            value: {
              text1: "قيمتنا",
              text2: "توفير الرعاية الصحية بشكل آمن لعدم الراحة من خلال خدماتنا المميزة لوجود عالمي في قطاع الضيافة من خلال التواجد ضمن نظامنا."
            },
          },
          department: {
            heading: "أقسامنا",
            text1: "نحن نقدم خدمات رعاية صحية متعددة التخصصات تحت سقف واحد",
            text2: "الموظفين المتاحين",
            INTERNAL: {
              head1: "INTERNAL",
              head2: "INTERNAL MEDICINE",
              text1: "توفير العلاج لمشاكل السن لدى البالغين والتي يتم علاجها من قبل أطباء متخصصين في هذا المجال.",
              text2: "يتعامل تخصص الطب الباطني في أسيا بلي كلينيك مع الوقاية من أمراض البالغين وتشخيصها وعلاجها. نحن نركز بشكل أساسي على فن التشخيص والعلاج بأدوية وافرة. نحن ندير الأمراض وفقًا للبروتوكولات المقبولة دوليًا والتي تؤدي إلى تحسين النتائج الصحية. يقدم قسم الطب الباطني لدينا مجموعة كاملة من الرعاية الطبية الوقائية، بما في ذلك الفحص الروتيني والتحصين، لمساعدة مرضانا على الحفاظ على صحتهم وتحسينها وكذلك لتحديد المرضى الذين هم أكثر عرضة للإصابة بحالات معينة مثل مرض السكري والكوليسترول والقلب. الأمراض.",
            },
            PEDIATRICS: {
              head1: "PEDIATRICS",
              text1: "يوفر أفضل رعاية طبية لطفلك، ويمنع الأمراض، ويعيد التغذية إلى الصحة حتى السن القانونية.",
              text2: "يتعامل قسم طب الأطفال في أسيا بلي كلينيك مع رعاية الأطفال والمراهقين. إن الاحتياجات الصحية للأطفال فريدة ومختلفة للغاية. نحن في أسيا بلي كلينيك نهدف إلى إحداث فرق كبير في الرعاية الصحية والرفاهية لكل من الأطفال والمراهقين. نحن نقدم تسهيلات للتشاور مع جميع مرضى الأطفال الذين يتم فحصهم والتحقيق معهم وعلاجهم على أساس المريض الخارجي. يتكون القسم من طبيب متخصص ومؤهل للغاية ولديه خبرة جيدة."
            },
            DERMATOLOGY: {
              head1: "DERMATOLOGY",
              text1: "يقدم قسم الأمراض الجلدية في APC رعاية تجميلية متخصصة للمرضى.",
              text2: "طب الأمراض الجلدية هو فرع الطب الذي يتعامل مع الجلد. وهو تخصص ذو جوانب طبية وجراحية. يضم قسم الأمراض الجلدية لدينا متخصصين متخصصين في التعامل وإدارة الأمراض المتعلقة بالبشرة والشعر والأظافر وبعض المشاكل التجميلية."
            },
            GYNECOLOGY: {
              head1: "GYNECOLOGY",
              text1: "يقدم قسم أمراض النساء في APC أفضل حزمة رعاية صحية لأحبائك.",
              text2: "مع فريق من أفضل أطباء أمراض النساء في المملكة العربية السعودية، نقدم رعاية شاملة للأمهات الحوامل أثناء فترة ما قبل الولادة والأمومة. علاوة على ذلك، يقدم قسم أمراض النساء في عياداتنا ومستشفياتنا في المملكة العربية السعودية العديد من الخدمات الأخرى للإدارة الشاملة التي تركز على المريض لحالات أمراض النساء في بيئة رعاية صحية تركز على الأسرة."
            },
            DENTISTRY: {
              head1: "DENTISTRY",
              text1: "الابتسامات التي نتلقاها تحكي قصة عملائنا السعداء لدينا، وعن خدمتنا الممتدة لعقد من الزمن.",
              text2: "يبذل موظفو APC جهدًا خاصًا لشرح كل شيء لك فيما يتعلق بحالتك والأدوية والعلاج. إن الحصول على أسنان قوية ومتألقة يعني تحقيق صحة أسنان جيدة. من المهم للغاية اتخاذ الخطوات الصحيحة للعناية بأسنانك ومنع أمراض الأسنان. إذا كان هناك أي شيء غير واضح بالنسبة لك، فلا تتردد في طرح الأسئلة. يقدم كل موظف خدمات طب الأسنان عالية الجودة في جو لطيف وفعال وودود."
            },
            ENT: {
              head1: "ENT",
              text1: "يمكن لأخصائيي السمع المعتمدين لدينا إجراء اختبارات السمع للمساعدة في العثور على السبب.",
              text2: "نحن في أسيا بلي كلينيك نقدم خدمة متكاملة للأذن والأنف والحنجرة والسمعيات. يقدم قسم السمعيات خدمة تشخيصية شاملة لتقييمات السمع والتوازن لجميع الفئات العمرية."
            },
            ORTHODONTICS: {
              head1: "ORTHODONTICS",
              text1: "فرع طب الأسنان المعني بنمو وتطور الهياكل الفموية الوجهية.",
              text2: "نحن نؤمن بأن تقديم علاج أسنان عالي الجودة يبدأ بخلق بيئة صديقة للمريض، مع الالتزام بأساليب التعقيم الصارمة. يتم تحديث العيادة بشكل مناسب وفقًا للتطورات الحديثة في طب الأسنان، مما يجعلها في متناول جميع مرضاها. على الرغم من كونه تخصصًا مشهورًا جدًا ومطلوبًا في تقويم الأسنان، إلا أن العيادة تفتخر بممارسة عامة ممتازة بنفس القدر بدءًا من علاجات قناة الجذر والتيجان وطب الأسنان التجميلي مع تقنيات تبييض الأسنان الأكثر توافقًا حيويًا."
            },
            OPHTHALMOLOGY: {
              head1: "OPHTHALMOLOGY",
              text1: "يتم تدريب أطباء العيون على إجراء اختبارات العين وتشخيص وعلاج ووصف الأدوية.",
              text2: "في APC، نأخذ الوقت الكافي للاستماع وطرح الأسئلة وفهم مخاوفك بشكل كامل. ومن خلال الجمع بين أعلى معايير رعاية المرضى وأحدث تقنيات التشخيص، فإننا نحدد مشكلات الرؤية الحالية ونساعد في الكشف عن العلامات التحذيرية لتحديات الرؤية المستقبلية. ثم نشرح لك النتائج التي توصلنا إليها بوضوح ونناقش معك جميع الخيارات الطبية والجراحية المناسبة."
            },
            ORTHOPEDICS: {
              head1: "ORTHOPEDICS",
              text1: "المساعدة في تشجيع التطوير وتسهيل التعافي، وتمكين الأشخاص من البقاء في العمل.",
              text2: "يتم إجراء عدد كبير من حالات الصدمات وعمليات تقويم العظام الاختيارية في العيادات الآسيوية الآسيوية كل عام. توفر مرافقنا رعاية استثنائية للمرضى الذين يعانون من إصابات العظام والمفاصل والعضلات / الهيكل العظمي المرتبطة بها، بدءًا من التشخيص وحتى الجراحة وطرق العلاج الأخرى."
            },
            PATHOLOGY: {
              head1: "PATHOLOGY",
              text1: "رعاية المصابين وتشخيص المرض من خلال فحص الأعضاء التي تم استئصالها جراحياً.",
              text2: "يتولى علماء الأمراض السريرية مسؤولية الأقسام الخاصة في المختبر حيث يقومون بإدارة الأعمال المختلفة. تقدم دراسة علم الأمراض السريرية مساهمة كبيرة في تمريض المصابين. ويخضعون لدورات تدريبية حيث يتلقون التدريب على أحدث الأدوات وأنواع المعدات التي تساعد في تشخيص العينة. بمساعدة أخصائي علم الأمراض الماهر، الذي نقدم له أفضل التسهيلات، سيساعدك على معرفة السبب الدقيق للأمراض، وبناءً على ذلك، يمكنك المضي قدمًا في عملية العلاج. يعتمد الإجراء بأكمله على النتائج السريرية."
            },
            RADIOLOGY: {
              head1: "RADIOLOGY",
              text1: "مع أحدث التقنيات لدينا في الصناعة، نحن نقدم أفضل النتائج وأكثرها كفاءة لخدماتنا.",
              text2: "نحن نقدم قيمة هائلة من خلال توفير عمل عالي الجودة بأسعار في متناول الجميع. نحن نقدم أسعارًا فائقة لا مثيل لها في الصناعة. أسعارنا لا مثيل لها بسبب العمليات المبسطة والكفاءة العالية. نحن نقوم بتقليل أو إلغاء النفقات على كل المستويات. ونتيجة لذلك، يوفر عملاؤنا بشكل كبير مع كل قراءة."
            },
            "OTHER SERVICES": {
              head1: "OTHER SERVICES",
              text1: "نحن نقدم خدمات مخبرية أخرى مثل اختبار PCR واختبارات الإقامة واختبارات البلدية والمزيد.",
              text2: "في أسيا بلي كلينيك، نتعامل مع مجموعة واسعة من الاختبارات والخدمات المخبرية المتعلقة بالمسائل القانونية والطبية مثل اختبار PCR، واختبار الإقامة، واختبار البلدية، والفحوصات الطبية وغيرها الكثير. نحن نؤمن بتقديم خدمات فعالة وسلسة. إن خط الاستفسار الخاص بنا والذي يعمل على مدار الساعة طوال أيام الأسبوع مفتوح دائمًا لخدمتك لتوضيح أي شيء يتعلق بالاختبارات أو خدماتنا المتاحة."
            },
            "GENERAL MEDICINE": {
              head1: "GENERAL MEDICINE",
              text2: "تقدم عياداتنا خدمات طبية وطبية شاملة لكل فرد من أفراد الأسرة. في APC، يهدف الممارسون العامون وطب الأسرة لدينا إلى أن يكونوا نقطة الاتصال الأساسية للخدمات الطبية الروتينية. يقوم ممارسو الطب العام وطب الأسرة بتشخيص وإدارة جميع أمراض المستوى الأساسي وإحالتك إلى الممارسين المتخصصين إذا لزم الأمر. أطباؤنا متخصصون أيضًا في تقديم رعاية صحية وقائية مُدارة بشكل جيد، وبالتالي توفير حل كامل وشامل للرعاية الصحية.",
            }
          },
          doctors: {
            head1: "أطباؤنا",
            text1: "لدينا بعض من أفضل الأطباء المتخصصين من جميع أنحاء العالم، فهم يتمتعون بسنوات من الخبرة ويقدمون العلاج القائم على الأدلة لضمان أفضل رعاية لك."
          },
          health_packages: {
            head1: "الحزم الصحية"
          },
          insurance: {
            head1: "الشركات التابعة للعنصرانك",
            text1: "اتصل بنا",
            text2: " لمعرفة المزيد عن بوليصة العنصرانك لدينا"
          },
          gallery: {
            head1: "معرضنا",
            head2: "تمكين فريقنا",
          },
          facilities: {
            head1: "منشأتنا",
            text1: "لدينا أفضل المرافق والتكنولوجيا التي نشغلها في هذا القطاع بين أيدينا لضيافتكم.",
            text2: "تواكب أسيا بلي كلينيك دائمًا أحدث التقنيات لتلبية احتياجاتك. نحن نتأكد من أن مرضانا يمكنهم تجربة الضيافة الدافئة بأسعار في متناول الجميع.",
          },
          app: {
            head1: "نقدم لكم تطبيقنا الآسيوي",
            text1: "صفحتنا التفاعلية الاجتماعية،",
            text2: "تواصل مع طبيبنا في أي وقت، من اختيارك",
            text3: "قناة التواصل الاجتماعي",
          },
          footer_one: {
            one: {
              text1: "ابحث عن عيادة قريبة منك",
              text2: "تجدنا على الخريطة"
            },
            two: {
              text1: "اتصل بنا للاستفسار",
              text2: "٠١٢ ٥٣٨ ٠٨٩١"
            },
            three: {
              text1: "لا تتردد في مراسلتنا!",
              text2: "أرسل بريدا إلكترونيا"
            },
          },
          footer_two: {
            contacts: {
              head1: "اتصل بنا",
              text1: "المستوصف الآسيوي، مكة المكرمة، العزيزية، المملكة العربية السعودية",
              text2: "المستوصف الآسيوي، مكة المكرمة، زهرة كدي، المملكة العربية السعودية",
            },
            navigator: {
              head1: "روابط سريعة",
              home: 'بيت',
              about: 'عن',
              our_doctors: 'أطباؤنا',
              mission: 'المهمة والرؤية',
              departments: 'الأقسام',
              health_packages: 'الحزم الصحية',
              management: 'إدارة',
              insurance: 'عنصرانك',
            }
          },
          footer_three: {
            text1: "شركة آسيان بولي كلينك جميع الحقوق محفوظة",
            text2: "تصميم وتطوير بواسطة",
            text3: "CHZAPPS INDIA"
          },
          buttons: {
            view_more: "عرض المزيد",
            view_all: "عرض الكل",
            read_more: "اقرأ كل شيء",
          }
        }
      },
    }
  })